import { create } from 'apisauce';
import { message } from 'antd';
import { env } from '../app.config';
import Cookies from 'js-cookie';
let host = env.API;

let api = create({
  baseURL: host,
  headers: {
    Accept: 'application/vnd.github.v3+json',
    'x-api-lang': 'TR',
    'x-api-version': '5.0',
  },
});

api.addResponseTransform(({ data }) => {
  if (data) {
    console.log('data ', data);
    if (data.result && data.result_message.type === 'token_expire') {
      localStorage.clear();
      Cookies.remove('token');
      window.location.href = '/login';
    }
    if (data.result && data.result_message.type !== 'success') {
      message.error(data.result_message.message, 2);
    }
    if (data.result && data.result_message.message !== 'error') {
    }
  }
});

if (Cookies.get('token')) {
  api.setHeader('Authorization', 'Bearer ' + Cookies.get('token'));
}

export default api;

import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, selects } = props;
  let [lessons, setLesson] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;

  return (
    <Select
      value={record[name] || []}
      mode="multiple"
      placeholder="Şirket"
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      {selects &&
        selects.map((b) => (
          <Select.Option key={b} value={b}>
            {b}
          </Select.Option>
        ))}
    </Select>
  );
};

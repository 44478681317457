import React, { useEffect, useState, useMemo, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, InputNumber, Select, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { EventsPicker, MapInput, FileInput, GroupPicker, SendNotificationInput, LangPicker, CityPicker, MultiSelect, AttendeePickerOne } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";
import ReactQuill from 'react-quill';

const { Option } = Select;

const AttendeeEventAdd = (props) => {
  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let eventId = params.eventId !== 'add' ? params.eventId : false;

  let [languagesCms] = useGlobal("languagesCms");
  let [langCode,] = useGlobal("langCode");
  let [langCms] = useGlobal("langCms");


  let [data, setData] = useState({});
  let [events, setEvent] = useState([]);

  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }
  console.log("id",id)
  console.log("eventId",eventId)

  useEffect(() => {

    if (id && !eventId) {
      setLoading(true)
      api.get("/api/getEvents/" + id).then(({ data: { result, result_message } }) => {
        setLoading(false)
        if (result) {
          result.map((item, key) => {
            if (item.startDate && item.endDate) {
              let start = new Date(item.startDate)
              let end = new Date(item.endDate)
              var diff = (start.getTime() - end.getTime()) / 1000;
              diff /= 60;
              let minutesRound= Math.abs(Math.round(diff));
              var num = minutesRound;
              var hours = (num / 60);
              var rhours = Math.floor(hours);
              var minutes = (hours - rhours) * 60;
              var rminutes = Math.round(minutes);
              //x.totalScore=rhours + "," + rminutes ;
              item.score=rhours + "," + rminutes ;
            }
          });
        }
        setEvent(result);
      });
    }
    else if (id && eventId) {
      setLoading(true)
      api.get("/rest/events/" + eventId).then(({ data: { result, result_message } }) => {
        console.log("resut",result)
        if(result && result.joins){
          let joinAttendee=result.joins.find(z=>z.id==id)
          joinAttendee.eventName=result.langs[0].name+" "+result.city+ " "+moment(result.startDate).format('DD-MM-YYYY HH:mm')
          joinAttendee.kpi = joinAttendee.kpi ? joinAttendee.kpi : "0"
          joinAttendee.score = 0
          if (result.startDate && result.endDate) {
            let start = new Date(result.startDate)
            let end = new Date(result.endDate)
            var diff = (start.getTime() - end.getTime()) / 1000;
            diff /= 60;
              let minutesRound= Math.abs(Math.round(diff));
              var num = minutesRound;
              var hours = (num / 60);
              var rhours = Math.floor(hours);
              var minutes = (hours - rhours) * 60;
              var rminutes = Math.round(minutes);
              //x.totalScore=rhours + "," + rminutes ;
              joinAttendee.score=rhours + "," + rminutes ;
          }
          setData(joinAttendee);
        }
        setLoading(false)
      });
      // api.get("/rest/events/" + eventId).then(({ data: { result, result_message } }) => {

      //   setLoading(false)
      //   if (result) {
      //     result.map((item, key) => {
      //       if (item.startDate && item.endDate) {
      //         let start = new Date(item.startDate)
      //         let end = new Date(item.endDate)
      //         var diff = (start.getTime() - end.getTime()) / 1000;
      //         diff /= 60;
      //         diff /= 60;
      //         let minutes = Math.abs(Math.round(diff));
      //         item.score = minutes
      //       }
      //     });
      //   }
      //   setEvent(result);
      // });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
console.log(data)
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      data.user_id = id
      data.eventId=eventId
      api.post("/api/joinEvent", data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          history.push('/attendeeEventList/detail/' + id)
        } else {
          message.error(result_message.message, 2);
        }
      })
    }
  };
  console.log(data)
  let imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
      quill.setSelection(range.index + 1);

      let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data' } }).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          return result[0].url;
        }
      });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  }

  const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code', 'script', 'clean']
  const quillmodules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        ['link', 'image', 'video'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { align: [] },
          { direction: 'rtl' },
        ],
        ['clean'],
        ['code-block'],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ script: 'sub' }, { script: 'super' }, 'formula']
      ],
      handlers: {
        image: imageHandler
      }
    }
  }), [])
console.log(events)

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/attendeeEventList/detail/' + id}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                {!eventId &&
                <Form.Item label={"Etkinlik"}>

                <Select defaultValue={"Etkinlik Seç"} style={{ width: 250 }} onChange={v => { setData({ ...data, event: v }) }}>
                  {events &&
                    events.map((b) => (
                      <Select.Option value={b._id}>{b.name+" "+b.city+" "+moment(b.startDate).format('DD-MM-YYYY HH:mm')}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
                }
                {eventId &&
                  <Form.Item label={"Etkinlik Adı"}>

                  <Input disabled={true}
                    value={data.eventName}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                }

              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item label={"Kazanılan Puan"}>

                    <Input
                      value={(!eventId && events && events.length>0 && events.find(z => z._id == data.event) && events.find(z => z._id == data.event).score) || (eventId && data && data.score)}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

              </Col>
               <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label={"KPI Puanı"}>
                  <Input name="kpi" value={(eventId && data && data.kpi)}
                    onChange={e => setData({ ...data, kpi: e.target.value })} />
                    <Alert message={"KPI değerini dakika olarak hesaplayıp giriniz."} banner />
                </Form.Item>
              </Col> 
            </Row>

            {/* <Select
        //value={record[name] || []}
        showSearch={true}
        placeholder={"Etkinlik Seç"}
        onChange={(v) => setEvent({ ...record,event_id:v._id })}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {events &&
          events.map((b) => (
            
            <Select.Option key={b._id} value={b._id}>
              {b.name}
            </Select.Option>
          ))}
      </Select> */}

            {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={"Etkinlikler"}>
                  <EventsPicker languagesCms={languagesCms} record={data} attendeeId={id} setRecord={setData} name="events" />
                </Form.Item>
              </Col> */}

            <Row direction="row">
              <Col span={24}>
              </Col>


            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      </div>
    </div>
  );
};



export default AttendeeEventAdd;

import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm,Card } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';

import {

  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

const PrideSource = (props) => {


const gridStyle1 = {
  width: '25%',
  height: '200px',
  textAlign: 'center',
  'background-color':'#8E44AD',
  padding:'80px',
  'font-size': '25px',
  'border-radius': '80px 20px',
  'text-stroke': '4px black', /* width and color */
  'text-shadow': '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
  'font-family': 'sans',
   'color': 'white'
};
const gridStyle2 = {
  width: '25%',
  height: '200px',
  textAlign: 'center', 
  'background-color':'#C0392B',
  padding:'80px',
  'font-size': '25px',
  'border-radius': '80px 20px',
  'text-stroke': '4px black', /* width and color */
  'text-shadow': '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
  'font-family': 'sans',
   'color': 'white'
};
const gridStyle3 = {
  width: '25%',
  height: '200px',
  textAlign: 'center', 
  'background-color':'#2980B9',
  padding:'80px',
  'font-size': '25px',
  'border-radius': '80px 20px',
  'text-stroke': '4px black', /* width and color */
  'text-shadow': '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
  'font-family': 'sans',
   'color': 'white'
};
const gridStyle4 = {
  width: '25%',
  height: '200px',
  textAlign: 'center', 
  'background-color':'#D35400',
  padding:'80px',
  'font-size': '25px',
  'border-radius': '80px 20px',
  'text-stroke': '4px black', /* width and color */
  'text-shadow': '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
  'font-family': 'sans',
   'color': 'white'
};
const cardStyle = {
    textAlign: 'center',
  };
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }



  let get = async () => {

    let restData = await api
      .get(`/api/prideSource`);
      if(restData && restData.data && restData.data.result)
    setData(restData.data.result);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);



console.log(data)

  return (
    <div style={{marginBottom: 5}}>
<Card style={cardStyle} title="GURUR TABLOSU">
  <div>
  <Card.Grid style={gridStyle1}>{data.totalEventCount } Etkinlik</Card.Grid>
  </div>
<div>
<Card.Grid style={gridStyle2}>{data.totalCityCount } İl</Card.Grid>
</div>
<div>
<Card.Grid style={gridStyle3}>{data.totalUser } Katılımcı</Card.Grid>
</div>
<div>
<Card.Grid style={gridStyle4}>{data.totalScore} Gönüllü Saati</Card.Grid>
</div>

  </Card>
    </div>
  );
};

export default PrideSource;
